import React from 'react'
import { graphql, PageProps } from 'gatsby'
import LazyLoad from 'react-lazyload'

import useBreakpoint from 'hooks/useBreakpoint'

import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'

import MapContainer from 'components/atoms/MapContainer'

import { Hero } from 'components/organisms/HomePage/Hero'
import { QualityOfLife } from 'components/organisms/HomePage/QualityOfLife'
import { SmartDesign } from 'components/organisms/HomePage/SmartDesign'

const WithCareForSpace = React.lazy(
  () => import('components/organisms/HomePage/WithCareForSpace')
)
const SaveCredit = React.lazy(
  () => import('components/organisms/HomePage/SaveCredit')
)
const Location = React.lazy(
  () => import('components/organisms/HomePage/Location')
)
const News = React.lazy(() => import('components/organisms/HomePage/News'))
const Gallery = React.lazy(
  () => import('components/organisms/HomePage/Gallery')
)
const Contact = React.lazy(() => import('components/organisms/Contact'))

const IndexPage: React.FC<PageProps<Queries.HomePageQuery>> = ({ data }) => {
  const PAGE = data.wpPage
  const PAGE_SEO = data.wpPage?.seo!
  const POPUP = data.wpPage?.TypePage ?? null

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const PageData = data.wpPage?.homePage!
  const { md } = useBreakpoint()

  const HeroData = {
    heading: PageData?.homepageHeroTitle!,
    description: PageData?.homepageHeroDescription!,
    image: {
      src: PageData?.homepageHeroImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PageData?.homepageHeroImg?.altText!,
    },
  }

  const QualityOfLifeData = {
    heading: PageData?.homepageQualityTitle!,
    tiles: PageData?.homepageQualityTiles?.map((item) => ({
      heading: item?.homepageQualityTilesTitle!,
      description: item?.homepageQualityTilesDescription!,
      image: {
        src: item?.homepageQualityTilesImg?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: item?.homepageQualityTilesImg?.altText!,
      },
      buttonText: item?.homepageQualityTilesButtonText!,
      buttonLink: item?.homepageQualityTilesButtonLink!,
    }))!,
  }

  const SmartDesignData = {
    heading: PageData?.homepageProjectTitle!,
    subHeading: PageData?.homepageProjectSubtitle!,
    contentBox: {
      heading:
        PageData?.homepageProjectChosenFlat?.homepageProjectChosenFlatTitle!,
      description:
        PageData?.homepageProjectChosenFlat
          ?.homepageProjectChosenFlatDescription!,
      buttonText:
        PageData.homepageProjectChosenFlat
          ?.homepageProjectChosenFlatButtonText!,
      buttonLink:
        PageData.homepageProjectChosenFlat
          ?.homepageProjectChosenFlatButtonLink!,
    },
    flats:
      PageData.homepageProjectChosenFlats?.map((item) => ({
        image: {
          src: item?.homepageProjectChosenFlatsImage?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: item?.homepageProjectChosenFlatsImage?.altText!,
        },
        number: item?.homepageProjectChosenFlatsNumber!,
        floor: item?.homepageProjectChosenFlatsFloor!,
        rooms: item?.homepageProjectChosenFlatsRooms!,
        area: item?.homepageProjectChosenFlatsArea!,
        link: item?.homepageProjectChosenFlatsLink!,
      })) || [],
  }

  const WithCareForSpaceData = {
    heading: 'Z DBAŁOŚCI O WSPÓLNĄ PRZESTRZEŃ',
    slides: PageData?.homepageSpacesCommonSpaces?.map((item, index) => ({
      positionRight: index % 2 === 1!,
      reversed: item?.homepageSpacesCommonSpacesSite === 'right'!,
      image: {
        src: item?.homepageSpacesCommonSpacesSingle?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: item?.homepageSpacesCommonSpacesSingle?.altText!,
      },
      label: item?.homepageSpacesCommonSpacesTitle!,
      text: item?.homepageSpacesCommonSpacesDescription!,
    }))!,
  }

  const SaveCreditData = {
    heading: PageData?.homepageCreditsTitle!,
    description: PageData?.homepageCreditsDescription!,
    buttonHeading: PageData?.homepageCreditsCommunication!,
    buttonText: PageData?.homepageCreditsButtonText!,
    buttonLink: PageData?.homepageCreditsButtonLink!,
    image: {
      src: PageData?.homepageCreditsImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PageData?.homepageCreditsImg?.altText!,
    },
  }

  const LocationData = {
    heading: PageData?.homepageLocationTitle!,
    subHeading: PageData?.homepageLocationSubtitle!,
    description: PageData?.homepageLocationDescription!,
    buttonText: PageData?.homepageLocationButtonText!,
    buttonLink: PageData?.homepageLocationButtonLink!,
    locationCords: {
      lat: Number(
        PageData?.homepageLocationInvestmentLocation
          ?.homepageLocationInvestmentLocationLat!
      ),
      lng: Number(
        PageData?.homepageLocationInvestmentLocation
          ?.homepageLocationInvestmentLocationLng!
      ),
    },
  }

  const NewsData = {
    heading: PageData?.homepageNewsTitle!,
    tiles: data.allWpNewsSingle.nodes.map((news) => ({
      image: {
        src: news.newsSingle?.newsSingleGallery?.[0]?.newsSingleGalleryImg
          ?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: news.newsSingle?.newsSingleGallery?.[0]?.newsSingleGalleryImg
          ?.altText!,
      },
      heading: news.title!,
      text: news.newsSingle?.newsSingleExcerpt!,
      date: new Date(news.date!).toLocaleDateString(),
      link: `/aktualnosci/${news.slug}`,
    })),
  }

  const GalleryData = {
    heading: PageData?.homepageGalleryTitle!,
    allImages: PageData?.homepageGalleryImgs?.map((item) => ({
      image: {
        src: item?.homepageGalleryImgsSingle?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: item?.homepageGalleryImgsSingle?.altText!,
      },
    }))!,
  }

  const ContactData = {
    heading: PageData?.homepageContactTitle!,
    formTitle: PageData?.homepageContactFormTitle!,
    image: {
      src: PageData?.homepageContactImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PageData?.homepageContactImg?.altText!,
    },
  }

  return (
    <Layout
      popup={
        POPUP
          ? {
              image: {
                src: POPUP?.popupImage?.localFile?.childImageSharp
                  ?.gatsbyImageData!,
                alt: POPUP?.popupImage?.altText!,
              },
              active: POPUP?.popupActive!,
              activeMobile: POPUP?.popupActiveMobile!,
              delay: POPUP?.popupDelay!,
              link: POPUP?.popupLink!,
            }
          : undefined
      }
    >
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <MapContainer size="big" position={['bottom', 'right']}>
        <Hero
          heading={HeroData.heading}
          description={HeroData.description}
          image={HeroData.image}
        />
      </MapContainer>

      <QualityOfLife
        heading={QualityOfLifeData.heading}
        tiles={QualityOfLifeData.tiles}
      />

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <MapContainer size="medium" position={['bottom', 'left']}>
            <SmartDesign
              heading={SmartDesignData.heading}
              subHeading={SmartDesignData.subHeading}
              contentBox={SmartDesignData.contentBox}
              flats={SmartDesignData.flats}
            />
          </MapContainer>
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <WithCareForSpace
            heading={WithCareForSpaceData.heading}
            slides={WithCareForSpaceData.slides}
          />
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <SaveCredit
            heading={SaveCreditData.heading}
            description={SaveCreditData.description}
            image={SaveCreditData.image}
            buttonHeading={SaveCreditData.buttonHeading}
            buttonText={SaveCreditData.buttonText}
            buttonLink={SaveCreditData.buttonLink}
          />
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <Location
            heading={LocationData.heading}
            subHeading={LocationData.subHeading}
            buttonText={LocationData.buttonText}
            buttonLink={LocationData.buttonLink}
            description={LocationData.description}
            locationCords={LocationData.locationCords}
          />
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <News heading={NewsData.heading} tiles={NewsData.tiles} />
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <MapContainer size="small" position={['top', 'left']}>
            <Gallery
              heading={GalleryData.heading}
              allImages={GalleryData.allImages}
            />
          </MapContainer>
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <Contact
            heading={ContactData.heading}
            formTitle={ContactData.formTitle}
            image={ContactData.image}
          />
        </React.Suspense>
      </LazyLoad>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePage {
    wpPage(slug: { regex: "/strona-glowna/" }) {
      TypePage {
        popupActive
        popupActiveMobile
        popupDelay
        popupLink
        popupImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
      }
      homePage {
        homepageHeroTitle
        homepageHeroDescription
        homepageHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        homepageContactTitle
        homepageContactFormTitle
        homepageContactImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        homepageQualityTitle
        homepageQualityTiles {
          homepageQualityTilesTitle
          homepageQualityTilesDescription
          homepageQualityTilesImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 3000)
              }
            }
          }
          homepageQualityTilesButtonText
          homepageQualityTilesButtonLink
        }
        homepageProjectTitle
        homepageProjectSubtitle
        homepageProjectChosenFlat {
          homepageProjectChosenFlatTitle
          homepageProjectChosenFlatDescription
          homepageProjectChosenFlatButtonText
          homepageProjectChosenFlatButtonLink
        }
        homepageSpacesCommonSpaces {
          homepageSpacesCommonSpacesTitle
          homepageSpacesCommonSpacesDescription
          homepageSpacesCommonSpacesSite
          homepageSpacesCommonSpacesSingle {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
        homepageCreditsTitle
        homepageCreditsDescription
        homepageCreditsCommunication
        homepageCreditsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
        }
        homepageCreditsButtonText
        homepageCreditsButtonLink
        homepageLocationTitle
        homepageLocationSubtitle
        homepageLocationDescription
        homepageLocationButtonText
        homepageLocationButtonLink
        homepageLocationInvestmentLocation {
          homepageLocationInvestmentLocationLat
          homepageLocationInvestmentLocationLng
        }
        homepageNewsTitle
        homepageGalleryTitle
        homepageGalleryImgs {
          homepageGalleryImgsSingle {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
        homepageProjectChosenFlats {
          homepageProjectChosenFlatsArea
          homepageProjectChosenFlatsFloor
          homepageProjectChosenFlatsLink
          homepageProjectChosenFlatsNumber
          homepageProjectChosenFlatsRooms
          homepageProjectChosenFlatsImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
      seo {
        ...WpSEO
      }
    }
    allWpNewsSingle(limit: 6) {
      nodes {
        title
        slug
        date
        newsSingle {
          newsSingleExcerpt
          newsSingleGallery {
            newsSingleGalleryImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400)
                }
              }
            }
          }
        }
      }
    }
  }
`
